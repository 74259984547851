<template>
  <div v-loading="loading">
    <div class="all-template-container">
      <div v-if="fileExists">
        <div
          style="
            margin-left: 40px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="d-flex">
            <el-link
              icon="el-icon-arrow-left"
              :underline="false"
              class="mr-2"
              @click="goback"
              v-if="showBack"
              >Back</el-link
            >
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mt-2">
              <el-breadcrumb-item>
                <el-link
                  style="cursor: pointer"
                  :underline="false"
                  type="primary"
                  @click="openFile(null)"
                  >/</el-link
                >
              </el-breadcrumb-item>
              <el-breadcrumb-item v-for="path in filePath" :key="path._id">
                <el-link
                  style="cursor: pointer"
                  :underline="false"
                  type="primary"
                  @click="openFile(path)"
                  >{{ path.name }}</el-link
                >
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="mr-2">
            <el-link
              type="success"
              :underline="false"
              @click="confirmApprove"
              v-if="isApprovingUser && selectedFiles.length"
              class="mr-1"
              >Approve ({{ selectedFiles.length }})</el-link
            >
            <el-link
              type="danger"
              :underline="false"
              @click="openRejectModal"
              v-if="isApprovingUser && selectedFiles.length"
              class="mr-1"
              >Reject ({{ selectedFiles.length }})</el-link
            >
            <el-link  v-if="checkPerimission('BULK DELETE')" class="m-lr-2 deletefiles" type="primary"
              :underline="false"  @click="openDeleteFiles">Bulk Delete</el-link>
            <button
              type="primary"
              :underline="false"
              @click="openNew"
              v-if="checkPermission"
              class="btn btn-sm btn-outline-primary m-lr-1"
              >Create new</button
            >
          </div>
        </div>
        <div v-if="allFiles && allFiles.length">
          <el-scrollbar wrap-style="max-height: 600px;">
            <div class="files-container">
              <div
                v-for="file in allFiles"
                :key="file._id"
                :class="
                  selectedFiles.includes(file._id)
                    ? 'single-file selected_file'
                    : 'single-file hover-file'
                "
                @dblclick="openFile(file)"
                @click="selectFile(file)"
              >
                <div class="file-header" :style="getFileStyles(file)">
                  <i class="el-icon-folder" v-if="file.type == 'FOLDER'"></i>
                  <i
                    class="el-icon-folder"
                    v-else-if="file.type == 'UPLOAD_FOLDER'"
                  ></i>
                  <i
                    class="el-icon-picture-outline"
                    v-else-if="file.file_format && isImage(file.file_format)"
                  ></i>
                  <i
                    class="el-icon-video-camera"
                    v-else-if="file.file_format && isVideo(file.file_format)"
                  ></i>
                  <i class="el-icon-tickets" v-else></i>
                </div>
                <div class="file-title">
                  <el-popover
                    placement="top-start"
                    class="item"
                    effect="dark"
                    trigger="hover"
                    :title="getFileName(file)"
                  >
                    <div v-if="file.approval_status == 'APPROVED'">
                      File approved by {{ file.approved_by }} on
                      {{ file.approved_at | globalDateTimeFormat }}
                    </div>
                    <div v-else-if="file.approval_status == 'REJECTED'">
                      File rejected by {{ file.rejected_by }} on
                      {{ file.rejected_at | globalDateTimeFormat }}
                      <br />
                      <span>Reason: </span>
                      <span>{{ file.reject_note }}</span>
                    </div>
                    <span slot="reference">{{
                      file.name | truncate(14, "...")
                    }}</span>
                  </el-popover>
                  <el-dropdown class="action-button">
                    <i
                      class="el-icon-more"
                      style="transform: rotate(90deg)"
                    ></i>
                    <el-dropdown-menu slot="dropdown">
                      <a
                        @click="openShareModal(file)"
                        v-if="
                          checkPermissionByFile(file) &&
                          checkPerimission('SHARE')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-share"></i> Share</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="openEditModal(file)"
                        v-if="
                          checkPermissionByFile(file, 'RENAME') &&
                          checkPerimission('RENAME')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-edit"></i> Rename</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="openSettingsModal(file)"
                        v-if="
                          file.type !== 'FILE' &&
                          checkPermissionByFile(file, 'SETTINGS') &&
                          checkPerimission('SETTINGS')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-setting"></i>
                          Settings</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          file.type == 'FILE' && checkPerimission('DOWNLOAD')
                        "
                        @click="downloadFile(file)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-download"></i>
                          Download</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="onDelete(file)"
                        v-if="
                          checkPermissionByFile(file, 'DELETE') &&
                          checkPerimission('DELETE')
                        "
                      >
                        <el-dropdown-item>
                          <i class="el-icon-delete"></i>
                          Delete</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="no-files" v-else>
          <el-empty description="No files">
            <el-link
              type="primary"
              :underline="false"
              @click="openNew"
              v-if="checkPermission"
              >Create new</el-link
            >
          </el-empty>
        </div>
      </div>
      <div v-else class="invalid-action">
        <h2>Folder dosen't exist</h2>
        <el-link type="primary" :underline="false" @click="openFile(null)"
          >Go back</el-link
        >
      </div>
      
    </div>
    <!-- Add new file modal -->
    <dialog-component
      :visible="openNewFileModal"
      :title="'Add New File or Folder'"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      @before-close="resetModal"
     
    >
      <div class="m-tb-2 mr-2" @keyup.enter="handleEnterKeyy">
        <div>
          Type
          <el-select
            placeholder="Select type"
            v-model="newFile.type"
            style="width: 100%"
            size="small"
          >
            <el-option
              value="FILE"
              label="File"
              v-if="checkPermission || isInUploadFolder"
            ></el-option>
            <el-option
              value="FOLDER"
              label="Folder"
              v-if="!isInUploadFolder"
            ></el-option>
            <el-option
              v-if="showBack && (!filePath || !filePath.length)"
              value="UPLOAD_FOLDER"
              label="Upload Folder"
            ></el-option>
          </el-select>
        </div>
        <div
          class="mt-1"
          v-if="newFile.type == 'FOLDER' || newFile.type == 'UPLOAD_FOLDER'"
        >
          Name
          <el-input v-model="newFile.name" placeholder="Enter name"></el-input>
        </div>
        <div v-if="newFile.type == 'UPLOAD_FOLDER'" class="mt-1">
          <label>Folder color</label><br />
          <el-color-picker v-model="newFile.color"></el-color-picker>
        </div>
        <div v-if="newFile.type == 'FILE'" class="mt-1">
          Upload file
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :auto-upload="false"
            :on-change="upload"
            multiple
          >
            <div class="upload-attchment-box">
              <img
                src="@/assets/img/fields/attachment-filling.svg"
                alt="Upload File"
                height="20"
              />
              <h4 class="ml-1">Upload a file</h4>
              <div slot="tip" class="el-upload__tip">
                files with a size less than {{ fileSize }}MB
              </div>
            </div>
          </el-upload>
          <div
            class="mt-1"
            v-if="newFile && newFile.files && newFile.files.length"
          >
            <div v-for="(file, i) in newFile.files" :key="i" class="d-flex">
              <i class="el-icon-document" style="margin-top: 5px"></i>
              <span>{{ file.name }}</span>
              <el-link
                icon="el-icon-close"
                type="primary"
                @click="removeFile(i)"
                :underline="false"
                style="margin-left: 5px"
              ></el-link>
            </div>
          </div>
        </div>
        <div
          v-if="
            newFile.type &&
            newFile.type != 'UPLOAD_FOLDER' &&
            checkPerimission('SHARE')
          "
        >
          <div v-if="isEntityData" class="mt-1">
            Share with
            <el-select
              v-model="newFile.entityRelationships"
              clearable
              placeholder="Select"
              style="width: 100%"
              multiple
              size="small"
            >
              <el-option
                v-for="relation in selectedEntityRelationships"
                :key="relation._id + '_' + relation.relationship_id"
                :label="relation.relationship_title"
                :value="relation.relationship_id"
                >{{ relation.relationship_title }}</el-option
              >
            </el-select>
          </div>
          <div v-else>
            Share with
            <el-select
              v-model="newFile.sharedList"
              multiple
              placeholder="Select entity"
              style="width: 100%"
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) => e._id != this.entityId
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div
          class="mt-1"
          v-if="newFile.type == 'FOLDER' || newFile.type == 'UPLOAD_FOLDER'"
        >
          <el-checkbox v-model="newFile.permission_settings.lock_folder"
            >Lock folder?</el-checkbox
          >
          <div
            class="d-flex mt-1 mb-1"
            v-if="newFile.permission_settings.lock_folder"
          >
            <span>Lock folder for</span>
            <el-select
              v-model="newFile.permission_settings.application_users"
              clearable
              placeholder="Select user"
              multiple
              size="mini"
              style="width: 120px"
              collapse-tags
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e._id != this.entityId &&
                    (e.entity_type == 'BUSINESS' ||
                      e.entity_type == 'INDIVIDUAL')
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
            <span class="mt-1">after </span>
            <el-input-number
              :min="0"
              type="number"
              size="mini"
              v-model="newFile.permission_settings.value"
            ></el-input-number>
            <el-select
              v-model="newFile.permission_settings.type"
              placeholder="Type"
              size="mini"
              style="width: 80px"
            >
              <el-option value="DAY" label="Day"></el-option>
              <el-option value="MONTH" label="Months"></el-option>
              <el-option value="YEAR" label="Years"></el-option>
            </el-select>
            from created date
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                'After that selected user not able to do upload files into current folder. Taking 0 will be current' +
                newFile.permission_settings.type
              "
              placement="bottom"
            >
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkDisabled"
          @click="save"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <!-- Edit file modal -->
    <dialog-component
      :visible="editModal"
      :title="'Rename'"
      @before-close="resetModal"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      :containerHeighgt="getIsMobile ? '100%' : '50%'"
    >
      <div class="m-tb-2 mr-2">
        <div @keyup.enter="handleEnterKey">
          Name
          <el-input v-model="editForm.name" placeholder="Enter name"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="!editForm.name"
          @click="update"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>

    <!-- share modal -->
    <dialog-component
      :visible="shareModal"
      :title="'Share'"
      @before-close="resetModal"
      :containerWidth="'25%'"
    >
      <div v-loading="loading">
        <div v-if="isEntityData">
          Share with
          <el-select
            v-model="entityRelationships"
            clearable
            placeholder="Select"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="relation in selectedEntityRelationships"
              :key="relation._id + '_' + relation.relationship_id"
              :label="relation.relationship_title"
              :value="relation.relationship_id"
              >{{ relation.relationship_title }}</el-option
            >
          </el-select>
        </div>
        <div v-else>
          Share with
          <el-select v-model="sharedList" multiple placeholder="Select entity">
            <el-option
              v-for="(entity, i) in (allEntities || []).filter(
                (e) => e._id != this.entityId
              )"
              :key="entity + 'entity' + i"
              :value="entity._id"
              :label="entity.name"
            ></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkShareDisable"
          @click="share"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>

    <!-- Settings modal -->
    <dialog-component
      :visible="settingsModal"
      :title="'Settings'"
      @before-close="resetModal"
      :containerWidth="'50%'"
    >
      <div class="popUp-content">
        <el-checkbox v-model="editForm.permission_settings.lock_folder"
          >Lock folder?</el-checkbox
        >
        <div
          class="d-flex mt-1 mb-1"
          v-if="editForm.permission_settings.lock_folder"
        >
          <span>Lock folder for</span>
          <el-select
            v-model="editForm.permission_settings.application_users"
            clearable
            placeholder="Select user"
            multiple
            size="mini"
            style="width: 120px"
            collapse-tags
          >
            <el-option
              v-for="(entity, i) in (allEntities || []).filter(
                (e) =>
                  e._id != this.entityId &&
                  (e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL')
              )"
              :key="entity + 'entity' + i"
              :value="entity._id"
              :label="entity.name"
            ></el-option>
          </el-select>
          <span class="mt-1">after </span>
          <el-input-number
            :min="0"
            type="number"
            size="mini"
            v-model="editForm.permission_settings.value"
          ></el-input-number>
          <el-select
            v-model="editForm.permission_settings.type"
            placeholder="Type"
            size="mini"
            style="width: 80px"
          >
            <el-option value="DAY" label="Day"></el-option>
            <el-option value="MONTH" label="Months"></el-option>
            <el-option value="YEAR" label="Years"></el-option>
          </el-select>
          from created date
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              'After that selected user not able to do upload files into current folder. Taking 0 will be current' +
              editForm.permission_settings.type
            "
            placement="bottom"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkLockFolderDisabled(editForm)"
          @click="update"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>

    <!--Preview image-->
    <dialog-component
      :visible="filePreviewModal"
      :title="'Preview'"
      @before-close="resetPreview"
    >
      <div
        class="popUp-content"
        v-loading="filePreviewLoading"
        style="min-height: 80vh; min-width: 80vh"
      >
        <el-image
          v-if="filePreviewData && previewFileType == 'IMAGE'"
          style="width: 100%; height: 80vh"
          :src="filePreviewData"
          :fit="'fill'"
        ></el-image>
        <video
          controls
          :src="filePreviewData"
          width="640"
          height="360"
          v-if="filePreviewData && previewFileType == 'VIDEO'"
        >
          Your browser does not support the to play video.
        </video>
      </div>
    </dialog-component>

    <!-- Reject reason -->
    <dialog-component
      :visible="rejectModal"
      :title="'Reject reason'"
      @before-close="resetRejectModal"
    >
      <div class="popUp-content">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Reason for reject"
          v-model="rejectReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetRejectModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="!rejectReason"
          :loading="loading"
          @click="rejectFile"
          >Reject</el-button
        >
      </span>
    </dialog-component>

    <dialog-component
      :visible="deleteFilesModal"
      :title="'Delete Files or Folders'"
      @before-close="deleteFilesModal=false"
      :containerWidth="'35%'"
      :containerHeight="'50%'"
    >
      <div class="m-tb-3" v-if="filteredFiles.length > 0">
        <el-input
          size="mini"
          v-model="fileSearchQuery"
          placeholder="Search files"
          class="mb-3 ml-2"
          prefix-icon="el-icon-search"
        />
        <el-checkbox
          :indeterminate="isIndeterminate"
          :value="isAllSelected"
          @change="toggleAllSelection"
          class="ml-3"
        >
          Select All
        </el-checkbox>
        <el-checkbox-group v-model="selectedDeleteFiles" class="checked-Files-container">
          <el-checkbox
            v-for="file in filteredFiles"
            :key="file._id"
            :label="file._id"
          >
            {{ file.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-else class="empty-state-message">
        No files or folders available.
      </div>

      <span slot="footer" class="dialog-footer">
            <button
              class="btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="deleteFilesModal = false"
            >
              <i class="fa fa-close"></i> Cancel
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn btn-outline-primary btn-sm m-lr-1"
              type="button"
              :disabled="!selectedDeleteFiles.length"
              :loading="loading"
              @click="deleteMultipleFiles(selectedDeleteFiles)"
            >
              <i class="fa fa-sign-out"></i> Confirm
            </button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/config/axios";
import moment from "moment";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import { postAPICall } from "../../helpers/httpHelper";

export default {
  data() {
    return {
      loading: false,
      openNewFileModal: false,
      newFile: {
        name: "",
        type: "",
        path: "",
        file: null,
        file_format: "",
        files: [],
        entityRelationships: [],
        sharedList: [],
        color: "#677788",
        permission_settings: {
          type: "DAY",
          value: 1,
          application_users: [],
          lock_folder: false,
        },
      },
      types: [
        {
          name: "File",
          type: "FILE",
        },
        {
          name: "Folder",
          type: "FOLDER",
        },
      ],
      allFiles: [],
      filePath: [],
      editModal: false,
      editForm: {
        name: "",
        id: "",
        upload_folder_name: "",
        permission_settings: {
          type: "DAY",
          value: 1,
          application_users: [],
          lock_folder: false,
        },
      },
      fileExists: true,
      currentFolder: null,
      parentPath: "entity-data",
      shareModal: false,
      sharingFile: null,
      sharedList: [],
      isEntityData: false,
      selectedEntityRelationships: [],
      entityRelationships: [],
      entityRelationshipMapData: [],
      filePreviewModal: false,
      filePreviewData: null,
      previewFileType: "IMAGE",
      filePreviewLoading: false,
      settingsModal: false,
      deleteFilesModal:false,
      fileSearchQuery: '',  
      selectedDeleteFiles:[],
      approvalColorsMap: {
        APPROVED: "#4CAF50",
        REJECTED: "#F44336",
        NEED_TO_BE_APPROVED: "#FBBC04",
        UPLOADED: "#007FFF",
      },
      selectedFiles: [],
      rejectModal: false,
      rejectReason: "",
      fileSize: 25,
    };
  },
  props: [
    "entityDataId",
    "entityId",
    "folder",
    "showBack",
    "isApplicationUserSide",
    "getEntityPermission",
    "primaryLabel",
  ],
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("templatesData", ["getUploadAndUpdateDocument"]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("fileManagement", [
      "getCreateFileStatus",
      "getCreatedFileData",
      "getCreatedFileError",
      "getAllFiles",
      "getFilePath",
      "getDeleteStatus",
      "getDeleteError",
      "getUpdateStatus",
      "getApproveFilesStatus",
      "getRejectFilesStatus",
    ]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityAllRelationshipData",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    checkDisabled() {
      if (this.newFile.type) {
        if (this.newFile.type == "FILE") {
          return !this.newFile.files || !this.newFile.files.length;
        }
        return this.newFile.name
          ? this.checkLockFolderDisabled(this.newFile)
          : true;
      }
      return true;
    },
    filteredFiles() {
      if (!this.fileSearchQuery) {
        return this.allFiles;
      }
      return this.allFiles.filter(file =>
        file.name.toLowerCase().includes(this.fileSearchQuery.toLowerCase())
      );
    },
    isAllSelected() {
      return this.filteredFiles.length > 0 &&
        this.filteredFiles.every(file => this.selectedDeleteFiles.includes(file._id));
    },
    isIndeterminate() {
      return this.filteredFiles.length > 0 &&
        this.selectedDeleteFiles.length > 0 &&
        !this.isAllSelected;
    },
    checkPermission() {
      //check lock folder access
      let checkLock = this.checkLockFolderAccess(this.currentFolder);
      if (!checkLock) {
        return false;
      }
      if (this.entityDataId && this.entityId) {
        if (this.folder) {
          if (this.isInUploadFolder) {
            return this.checkPerimission("ADD");
          }
          return (this.currentFolder && this.currentFolder.entity_data_ids
            ? this.currentFolder.entity_data_ids
            : []
          ).find((e) => e.entity_data_id == this.entityDataId)
            ? this.checkPerimission("ADD")
            : false;
        }
        return this.checkPerimission("ADD");
      } else if (this.entityId) {
        if (this.folder) {
          return (this.currentFolder && this.currentFolder.entity_ids
            ? this.currentFolder.entity_ids
            : []
          ).find((e) => e.entity_id == this.entityId && e.primary)
            ? this.checkPerimission("ADD")
            : false;
        }
        return this.checkPerimission("ADD");
      }
      return this.checkPerimission("ADD");
    },
    allEntities() {
      return this.getAllEntities?.data;
    },
    checkShareDisable() {
      if (this.isEntityData) {
        return false;
      } else {
        return false;
      }
    },
    isApplicationUser() {
      if (
        this.$route.name == "ApPreviewEntityData" &&
        this.$route.query?.activeTab == "files"
      ) {
        return true;
      }
      return false;
    },
    currentMenu() {
      if (
        this.getEntityMenu?.menu_list &&
        (this.$route.params.menuId || this.$route.query.menu_id)
      ) {
        return this.getEntityMenu.menu_list.find(
          (e) =>
            e._id == this.$route.params.menuId ||
            e._id == this.$route.query.menu_id
        );
      }
      return null;
    },
    isInUploadFolder() {
      if (this.filePath && this.entityDataId && this.entityId) {
        return this.filePath.find(
          (e) => !e.folder && e.type == "UPLOAD_FOLDER"
        );
      }
      return null;
    },
    checkUploadFolder() {
      return this.filePath.find((e) => !e.folder && e.type == "UPLOAD_FOLDER");
    },
    getUrlPath() {
      let query = "?key=" + Math.floor(Math.random() * 100000);
      Object.keys(this.$route.query || {}).forEach((k) => {
        if (k !== "menu_id" && k !== "filter" && k != "addNew" && k != "key") {
          query += "&" + k + "=" + encodeURIComponent(this.$route.query[k]);
        }
      });
      return query;
    },
    isRequestingUser() {
      return (
        this.currentFolder?.approval_settings?.requesting_users &&
        this.isApplicationUserSide &&
        this.currentFolder?.approval_settings?.requesting_users.includes(
          this.getApplicationUserType
        )
      );
    },
    checkApproverFolder() {
      return this.currentFolder?.approval_settings?.approval_folder;
    },
    isApprovingUser() {
      return (
        this.currentFolder?.approval_settings?.approver_user_ids &&
        (this.currentFolder?.approval_settings?.approver_user_ids.includes(
          this.getApplicationUserType
        ) ||
          this.currentFolder?.approval_settings?.approver_user_ids.includes(
            this.getCompanyUserRole
          ))
      );
    },
    getCompanyUserRole() {
      return this.getAuthenticatedUser?.activeRole?.userType_id;
    },
    getApplicationUserType() {
      let currentActiveWorkspace = (
        this.getAuthenticatedUser.contact_types || []
      ).find((e) => {
        let id =
          e.contact_type && e.contact_type._id
            ? e.contact_type._id
            : e.contact_type;
        if (id && id == this.getActiveContactType?.contact_type?._id) {
          return true;
        }
      });
      if (currentActiveWorkspace?.contact_type) {
        return currentActiveWorkspace.contact_type &&
          currentActiveWorkspace.contact_type._id
          ? currentActiveWorkspace.contact_type._id
          : currentActiveWorkspace.contact_type;
      }
      return null;
    },
  },
  mixins: [ComanyDocumentsPresignedURLsHelper],
  mounted() {
    this.getNecessaryInfo();
  },
  methods: {
    openDeleteFiles(){
      this.deleteFilesModal=true;
    },
    toggleAllSelection(checked) {
      if (checked) {
        this.selectedDeleteFiles = this.filteredFiles.map(file => file._id);
      } else {
        this.selectedDeleteFiles = [];
      }
    },
    openRejectModal() {
      this.rejectReason = "";
      this.rejectModal = true;
    },
    handleEnterKey(event) {
      if (event.keyCode === 13) {
        this.update()
      }
    },
    handleEnterKeyy(event){
      if(event.keyCode === 13){
        this.save()
      }
    },
    resetRejectModal() {
      this.rejectReason = "";
      this.rejectModal = false;
    },
    confirmApprove() {
      if (!this.selectedFiles.length) {
        this.$message.warning("Please select files");
        return;
      }
      this.$confirm(
        "Are you sure to approve " + this.selectedFiles.length + " files?",
        "Warning",
        {
          confirmButtonText: "Approve",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.approveFile();
      });
    },
    async approveFile() {
      this.loading = true;
      await this.$store.dispatch("fileManagement/approveFile", {
        file_ids: this.selectedFiles,
        parent_folder: this.currentFolder._id,
        entity_data_id: this.entityDataId,
      });
      if (this.getApproveFilesStatus) {
        this.$message.success("Approved successfully");
        this.selectedFiles = [];
        this.getNecessaryInfo();
      } else {
        this.$message.error(
          this.getDeleteError ? this.getDeleteError : "Error while approving"
        );
      }
      this.loading = false;
    },
    async rejectFile() {
      this.loading = true;
      if (!this.selectedFiles.length) {
        this.$message.warning("Please select files");
        return;
      }
      await this.$store.dispatch("fileManagement/rejectFile", {
        file_ids: this.selectedFiles,
        reject_note: this.rejectReason,
      });
      if (this.getRejectFilesStatus) {
        this.$message.success("Rejected successfully");
        this.selectedFiles = [];
        this.resetRejectModal();
        this.getNecessaryInfo();
      } else {
        this.$message.error(
          this.getDeleteError ? this.getDeleteError : "Error while rejecting"
        );
      }
      this.loading = false;
    },
    getFileStyles(file) {
      let styles = "";
      if (this.checkApproverFolder && file?.approval_folder) {
        styles += this.getApprovalColors(file);
      } else if (file.type == "UPLOAD_FOLDER") {
        styles += `color: ${file.color ? file.color : "#409EFF"};`;
      } else if (!this.checkPermissionByFile(file)) {
        styles += "color: #409EFF;";
      }
      return styles;
    },
    selectFile(file) {
      if (this.isApprovingUser && file.approval_folder && file.approval_status == "PENDING") {
        let index = this.selectedFiles.findIndex((e) => e == file._id);
        if (index == -1) {
          this.selectedFiles.push(file._id);
        } else {
          this.selectedFiles.splice(index, 1);
        }
      } else {
        this.selectedFiles = [];
      }
    },
    getApprovalColors(file) {
      if (file.approval_status !== "PENDING") {
        return `color: ${this.approvalColorsMap[file.approval_status]}`;
      } else if (this.isApprovingUser) {
        if (file.approval_status == "PENDING") {
          return `color: ${this.approvalColorsMap["NEED_TO_BE_APPROVED"]}`;
        }
        return `color: ${this.approvalColorsMap[file.approval_status]}`;
      } else if (this.isRequestingUser) {
        if (file.approval_status == "PENDING") {
          return `color: ${this.approvalColorsMap["UPLOADED"]}`;
        }
        return `color: ${this.approvalColorsMap[file.approval_status]}`;
      }
      return "";
    },
    isDateInRange(baseDate, type, value) {
      const startDate = moment(baseDate);
      let endDate;
      switch (type) {
        case "DAY":
          endDate = startDate.clone().add(value, "days");
          break;
        case "MONTH":
          endDate = startDate.clone().add(value, "months");
          break;
        case "YEAR":
          endDate = startDate.clone().add(value, "years");
          break;
        default:
          endDate = startDate.clone().add(value, "days");
          break;
      }
      const currentDate = moment();
      return currentDate.isAfter(endDate);
    },
    checkLockFolderAccess(folder) {
      if (
        folder?.permission_settings?.lock_folder &&
        this.isApplicationUserSide
      ) {
        if (this.getApplicationUserType) {
          let check = this.isDateInRange(
            folder.created_at,
            folder.permission_settings.type,
            folder.permission_settings.value
          );
          return folder?.permission_settings?.application_users.includes(
            this.getApplicationUserType
          ) && check
            ? false
            : true;
        }
        return true;
      }
      return true;
    },
    checkLockFolderDisabled(newFile) {
      if (newFile?.permission_settings?.lock_folder) {
        return (
          !newFile?.permission_settings?.type ||
          !newFile?.permission_settings?.application_users.length ||
          typeof this.newFile?.permission_settings?.value !== "number"
        );
      }
      return false;
    },
    getFileName(file) {
      if (file.upload_folder_name && !this.entityDataId) {
        return file.upload_folder_name + " - " + file.name;
      }
      return file.name;
    },
    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if(this.isRequestingUser && access == "ADD"){
          return this.isRequestingUser
        }
        if (this.getEntityPermission && this.currentMenu) {
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id
              ? this.currentMenu.entity_id._id
              : this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].files &&
              this.getEntityPermission.other_entities[entityId].files.indexOf(
                access
              ) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id
              ? this.currentMenu.relational_entity._id
              : this.currentMenu.relational_entity;
            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId].files &&
              this.getEntityPermission.relational_entities[
                entityId
              ].files.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu?.nested_relational_entity) {
            if (
              this.getEntityPermission &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.files.indexOf(access) != -1
            ) {
              return true;
            }
          }
          return false;
        }
        return this.currentMenu ? false : true;
      }
      return true;
    },
    async share() {
      let params = {};
      if (this.entityDataId) {
        params = {
          id: this.sharingFile._id,
          entity_ids: [
            ...this.entityRelationships.map((e) => {
              return {
                entity_id: e,
                primary: false,
                is_relation: true,
              };
            }),
          ],
          action: "RELATION_UPDATE",
        };
      } else {
        params = {
          id: this.sharingFile._id,
          entity_ids: [
            ...[
              {
                entity_id: this.entityId,
                primary: true,
              },
            ],
            ...this.sharedList.map((e) => {
              return {
                entity_id: e,
                primary: false,
              };
            }),
          ],
        };
      }
      this.loading = true;
      await this.$store.dispatch("fileManagement/updateFile", params);
      if (this.getUpdateStatus) {
        this.$message.success("Shared successfully");
        this.resetModal();
        this.getNecessaryInfo();
      } else {
        this.$message.error("Error while sharing!");
      }
      this.loading = false;
    },

    async getRelationships() {
      if (this.entityId) {
        await Promise.all([
          this.$store.dispatch(
            "entityRelationships/fetchEntityAllRelationshipData",
            {
              parent_entity_id: this.entityId,
              parent_entity_data_id: this.entityDataId,
            }
          ),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.entityId,
            include_type: true,
          }),
        ]);
        if (this.getEntityRelationships) {
          this.setRelationships(this.getEntityRelationships, this.entityId);
        }
        if (this.getEntityAllRelationshipData) {
          this.setEntityRelationshipMapData(
            this.getEntityAllRelationshipData,
            this.entityId
          );
        }
      }
    },
    setEntityRelationshipMapData(reationshipData) {
      this.entityRelationshipMapData = [];
      (reationshipData || []).forEach((relation) => {
        if (relation?.relationship_id && relation?.entity_data_ids) {
          this.entityRelationshipMapData = [
            ...this.entityRelationshipMapData,
            ...relation.entity_data_ids.filter((e) => e),
          ];
        }
      });
    },
    setRelationships(getEntityRelationships, entity_id) {
      this.selectedEntityRelationships = [...getEntityRelationships];
      // we have to add type owner of relationship
      this.selectedEntityRelationships = this.selectedEntityRelationships.map(
        (e) => {
          e.entity_id = entity_id;
          if (
            e.parent_entity_id == entity_id ||
            e.parent_entity_id._id == entity_id
          ) {
            e.owner_type = "PARENT";
            e.relationship_id = e.child_entity_id._id || e.child_entity_id;
            e.relationship_title = e.child_relation_title;
            e.relationship_entity_type = e.child_entity_id.entity_type || "";
          } else {
            e.owner_type = "CHILD";
            e.relationship_id = e.parent_entity_id._id || e.parent_entity_id;
            e.relationship_title = e.parent_relation_title;
            e.relationship_entity_type = e.parent_entity_id.entity_type || "";
          }
          return e;
        }
      );
      this.selectedEntityRelationships =
        this.selectedEntityRelationships.filter((e) => {
          if (e.owner_type == "CHILD" && !e.is_bidirectional) {
            return false;
          } else {
            return true;
          }
        });
    },
    async openShareModal(file) {
      if (this.entityDataId) {
        this.isEntityData = true;
        let relations = this.selectedEntityRelationships.flatMap(
          (e) => e.relationship_id
        );
        this.entityRelationships = (file.entity_ids || [])
          .filter((e) => e.is_relation && e.entity_id)
          .flatMap((e) => e.entity_id)
          .filter((e) => relations.indexOf(e) != -1);
      } else if (this.entityId) {
        this.sharedList = (file.entity_ids || [])
          .filter((e) => e.entity_id != this.entityId)
          .flatMap((e) => e.entity_id);
        await this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
        });
      }
      this.shareModal = true;
      this.sharingFile = file;
    },
    checkPermissionByFile(file, action) {
      if (
        file?.type == "UPLOAD_FOLDER" &&
        action != "DELETE" &&
        action != "RENAME"
      ) {
        return false;
      }
      if (this.entityDataId && this.entityId && file) {
        return (file.entity_data_ids || []).find(
          (e) => e.entity_data_id == this.entityDataId
        )
          ? true
          : false;
      } else if (this.entityId) {
        if (this.checkUploadFolder) {
          return true;
        }
        return (file.entity_ids || []).find(
          (e) => e.entity_id == this.entityId && e.primary
        )
          ? true
          : false;
      }
      return true;
    },
    goback() {
      this.$emit("back");
    },
    async update() {
      this.loading = true;
      let params = JSON.parse(JSON.stringify(this.editForm));
      if (!params.upload_folder_name) {
        delete params.upload_folder_name;
      }
      await this.$store.dispatch("fileManagement/updateFile", params);
      if (this.getUpdateStatus) {
        this.$message.success("File updated successfully");
        this.resetModal();
        this.getNecessaryInfo();
      } else {
        this.$message.error("Error while updating!");
      }
      this.loading = false;
    },
    openEditModal(file) {
      this.editForm.name = file.name;
      this.editForm.id = file._id;
      this.editForm.permission_settings = file.permission_settings;
      if (file.upload_folder_name && this.primaryLabel) {
        this.editForm.upload_folder_name = file.upload_folder_name;
      }
      this.editModal = true;
    },
    openSettingsModal(file) {
      this.editForm.id = file._id;
      this.editForm.permission_settings = file.permission_settings;
      this.editForm.name = file.name;
      if (file.upload_folder_name && this.primaryLabel) {
        this.editForm.upload_folder_name = file.upload_folder_name;
      }
      this.settingsModal = true;
    },
    async openFile(file) {
      if (file) {
        if (
          (file.type == "FOLDER" || file.type == "UPLOAD_FOLDER") &&
          (!this.folder || file._id != this.folder)
        ) {
          this.$emit("moveToFolder", file._id);
        } else if (
          this.isImage(file.file_format) ||
          this.isVideo(file.file_format)
        ) {
          this.filePreviewLoading = true;
          this.filePreviewModal = true;
          this.previewFileType = this.isVideo(file.file_format)
            ? "VIDEO"
            : "IMAGE";
          this.filePreviewData = await this.getImageDownloadUrl([file.path]);
          setTimeout(() => {
            this.filePreviewLoading = false;
          }, 1000);
        }
      } else {
        this.$emit("moveToFolder", "root");
      }
    },
    resetPreview() {
      this.filePreviewModal = false;
      this.filePreviewData = null;
      this.filePreviewLoading = false;
    },
    isImage(file) {
      const imageTypes = /^image\/(jpeg|png|gif|bmp|svg\+xml|jpeg)$/;
      return imageTypes.test(file);
    },
    isVideo(file) {
      const videoTypes = /^video\/(mp4|avi|mov|wmv|mkv|flv|webm|ogg)$/; // Add or remove video formats as needed
      return videoTypes.test(file);
    },
    async fetchFilePath() {
      await this.$store.dispatch("fileManagement/fetchFilePath", this.folder);
      if (this.getFilePath) {
        this.filePath = this.getFilePath
          .sort((a, b) => {
            return b.order - a.order;
          })
          .map((e) => {
            return {
              ...e.file,
            };
          });
      }
    },
    async getNecessaryInfo() {
      let params = {};
      if (this.folder) {
        params.folder = this.folder;
        await this.fetchFilePath(this.folder);
        if (this.isInUploadFolder) {
          params.upload_folder = true;
        }
      }
      if (this.entityDataId) {
        params.entity_data_id = this.entityDataId;
        await this.getRelationships();
        if (!this.folder) {
          params.relationship_entity_ids = this.entityRelationshipMapData;
        }
      }
      if (this.entityId) {
        params.entity_id = this.entityId;
      }
      params.role = this.getApplicationUserType || this.getCompanyUserRole;
      this.loading = true;
      this.allFiles = [];
      await this.$store.dispatch("fileManagement/getAllFiles", params);
      if (this.getAllFiles) {
        this.loading = false;
        if (this.folder) {
          this.fileExists = this.getAllFiles.file ? true : false;
          this.currentFolder = this.getAllFiles.file;
        }
        this.allFiles = [
          ...(this.getAllFiles.data || []).sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        ];
        if (this.currentFolder?.approval_settings?.approval_folder) {
          // this.allFiles = this.allFiles.filter((e) => {
          //   if (
          //     this.currentFolder?.approval_settings?.approval_folder &&
          //     e?.approval_folder &&
          //     e?.approval_status == "PENDING"
          //   ) {
          //     if(this.getAuthenticatedUser?.email == e.requested_user){
          //       return true
          //     }
          //     return false
          //   }
          //   return true;
          // });
        }
      } else {
        this.loading = false;
      }
    },
    prepareParams(newFile) {
      let params = { ...newFile };
      if (params.type == "FOLDER" || params.type == "UPLOAD_FOLDER") {
        if (params.path) {
          delete params.path;
        }
        if (params.file) {
          delete params.file;
        }
        if (params.type == "UPLOAD_FOLDER") {
          if (this.newFile.sharedList && this.newFile.sharedList.length) {
            delete this.newFile.sharedList;
          }
        }
      } else {
        if (
          this.currentFolder?.approval_settings?.approval_folder &&
          this.isRequestingUser
        ) {
          params.approval_folder = true;
          params.approval_status = "PENDING";
          params.requested_user = this.getAuthenticatedUser.email;
          params.approval_settings = this.currentFolder.approval_settings;
        }
      }
      if (this.entityDataId) {
        params["entity_data_ids"] = [
          {
            entity_data_id: this.entityDataId,
            primary: true,
          },
        ];
        if (
          this.newFile.entityRelationships &&
          this.newFile.entityRelationships.length
        ) {
          params["entity_ids"] = [
            ...this.newFile.entityRelationships.map((e) => {
              return {
                entity_id: e,
                primary: false,
                is_relation: true,
              };
            }),
          ];
          params["action"] = "RELATION_UPDATE";
        }
        if (this.isInUploadFolder && this.primaryLabel) {
          params["upload_folder_name"] = this.primaryLabel;
        }
      } else if (this.entityId) {
        let entityIds = [
          {
            entity_id: this.entityId,
            primary: true,
          },
        ];
        if (this.newFile.sharedList && this.newFile.sharedList.length) {
          entityIds = [
            ...entityIds,
            ...this.newFile.sharedList.map((e) => {
              return {
                entity_id: e,
                primary: false,
              };
            }),
          ];
        }
        params["entity_ids"] = [...entityIds];
      }
      if (this.folder) {
        params["folder"] = this.folder;
      } else {
        delete params.folder;
      }
      if (!this.checkPerimission("SHARE")) {
        params.entity_ids = (params.entity_ids || []).filter(
          (e) => !e.is_relation
        );
        if (params?.sharedList) {
          params.sharedList = [];
        }
      }
      params["url_path"] = this.getUrlPath;
      return params;
    },
    async downloadFile(file) {
      this.loading = true;
      await this.downloadFilesFromS3Paths([file.path], [file.name]);
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
    onDelete(file) {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteFile(file);
      });
    },
    async deleteFile(file) {
      this.loading = true;
      await this.$store.dispatch("fileManagement/deleteFile", file._id);
      if (this.getDeleteStatus) {
        this.$message.success("File deleted successfully");
        this.getNecessaryInfo();
      } else {
        this.$message.error(
          this.getDeleteError
            ? this.getDeleteError
            : "Error while uploading file"
        );
      }
    this.loading = false;
  },
    async deleteMultipleFiles(selectedDeleteFiles) {
      try {
        this.loading = true;
        if (!selectedDeleteFiles || selectedDeleteFiles.length === 0) {
          throw new Error("No files selected for deletion");
        }
        const response = await postAPICall('DELETE', '/fileManagement/delete-many', { file_ids: selectedDeleteFiles.join(',') });   
        if (response && response.success) {
          if (response.failed > 0) {
            this.$message.warning(`Some files could not be deleted: ${response.errors.map(e => e.message).join(", ")}`);
          } else {
            this.$message.success("All files deleted successfully");
          }
          this.getNecessaryInfo();
          this.deleteFilesModal = false;
        } else {
          this.$message.error(
            response && response.errors.length > 0
              ? `Errors: ${response.errors.map(e => e.message).join(", ")}`
              : "Error while deleting files"
          );
        }
      } catch (err) {
        this.$message.error(err.message || "Error while deleting files");
      } finally {
        this.loading = false;
      }
    },
    async createFileOrFolder() {
      this.loading = true;
      let params = this.prepareParams(this.newFile);
      await this.$store.dispatch("fileManagement/createNewFile", [params]);
      if (this.getCreateFileStatus) {
        this.$message.success("File saved successfully");
        this.resetModal();
        this.getNecessaryInfo();
      } else {
        this.$message.error("Error while uploading file");
      }
      this.loading = false;
    },
    save() {
      if (this.newFile?.type == "FILE") {
        this.uploadDocument();
      } else {
        this.createFileOrFolder();
      }
    },
    async uploadDocument() {
      this.loading = true;
      var formData = new FormData();
      this.newFile.files.forEach((el) => {
        formData.append(
          "files",
          el.file && el.file.raw !== undefined ? el.file.raw : el
        );
      });
      formData.append(
        "path",
        this.parentPath +
          (this.entityDataId ? "/" + this.entityDataId : "/" + this.entityId)
      );
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (filesData?.data?.data) {
        let mappedFileData = (this.newFile.files || []).map((file, i) => {
          return this.prepareParams({
            ...file,
            ...{ path: filesData.data.data[i], type: "FILE" },
          });
        });
        await this.$store.dispatch(
          "fileManagement/createNewFile",
          mappedFileData
        );
        if (this.getCreateFileStatus) {
          this.$message.success("File saved successfully");
          this.getNecessaryInfo();
          this.resetModal();
        } else {
          this.$message.error("Error while uploading file");
        }
      } else {
        this.$message.error(
          "Error while uploading " +
            (this.newFile.files.length == 1 ? "file" : "files")
        );
      }
      this.loading = false;
    },
    removeFile(index) {
      if (index > -1 && this.newFile.files[index]) {
        this.newFile.files.splice(index, 1);
      }
    },
    upload(file) {
      let currentFileSize = file.size / (1024 * 1024);
      if (currentFileSize < this.fileSize) {
        let newFile = {
          file_format: file.raw.type,
          file: file,
          name: file.name.includes(".") ? file.name.split(".")[0] : file.name,
        };
        this.newFile.files.push(newFile);
      } else {
        this.$message.error(
          "Large file. Maximum upload file size: " + this.fileSize + "MB"
        );
      }
    },
    resetModal() {
      this.openNewFileModal = false;
      this.newFile.path = "";
      this.newFile.file = "";
      this.newFile.name = "";
      this.newFile.file_format = "";
      this.newFile.entityRelationships = [];
      this.newFile.sharedList = [];
      this.editForm.name = "";
      this.editForm.id = "";
      this.editForm.upload_folder_name = "";
      this.editModal = false;
      this.newFile.type = "";
      this.newFile.files = [];
      this.newFile.permission_settings = {
        type: "DAY",
        value: 1,
        application_users: [],
        lock_folder: false,
      };
      this.editForm.permission_settings = {
        type: "DAY",
        value: 1,
        application_users: [],
        lock_folder: false,
      };
      this.settingsModal = false;
      //share options
      this.sharingFile = null;
      this.shareModal = false;
    },
    openNew() {
      if (this.entityDataId) {
        this.isEntityData = true;
        this.newFile.entityRelationships = (
          this.selectedEntityRelationships || []
        ).flatMap((e) => e.relationship_id);
      } else {
        this.newFile.sharedList = [];
      }
      this.openNewFileModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-template-container {
  border: 2px solid #e6e6e6;
  border-radius: 6px;
  width: 100% !important;
  padding: 10px;
  min-height: 400px;
  .no-files {
    text-align: center !important;
  }
  .invalid-action {
    text-align: center !important;
    color: red;
    h2 {
      margin-top: 10%;
    }
  }
  .files-container {
    display: flex;
    gap: 20px;
    padding: 10px;
    .selected_file {
      background-color: #eff8ff;
      border: 0.2px solid #409eff;
    }
    .hover-file {
      &:hover {
        background-color: rgba(231, 234, 243, 0.4);
        .file-title {
          .action-button {
            visibility: visible !important;
          }
        }
      }
    }
    .single-file {
      text-align: center !important;
      padding: 10px;
      .file-header {
        i {
          font-size: 50px;
        }
        img {
          width: 55px;
        }
      }
      cursor: pointer;
      .file-title {
        text-align: center !important;
        .action-button {
          visibility: hidden !important;
        }
      }
    }
  }
}
.checked-Files-container{
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.empty-state-message {
  text-align: center;
  color: #999;
  margin-top: 20px;
  font-size: 14px;
}
.create-btn{
  color:var(--primary-color)
}
.create-btn:hover{
  color: var(--primary-color);
}
.deletefiles{
  color: red;
}
.deletefiles:hover{
  color: red;
}
.btn-outline-primary {
  color: var(--primary-contrast-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
  height: 32px !important;
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  &:active:focus {
    color: var(--primary-contrast-color) !important;
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.2rem var(--primary-color) !important;
  }
  @media (max-width: 767px) {
    position: relative;
    bottom: 10px;
  }
}
</style>
